/* barba */
import barba from '@barba/core';

/* gsap */
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

/* imports */
import { viewport } from './components/viewport.js';
import { simpleMenu, updateHeader, updateMenu } from './components/header.js';
import { searchScrollAnimation, searchAnimation, search } from './components/search.js';
// import { showreel } from './pages/home.js';
import { mainMenu } from './components/main-menu.js';
import { accordion } from './components/accordion.js';
import { slider, plyr } from './pages/student.js';
import { viewer } from './pages/yearbook.js';
// import { scrollToAcc } from './pages/information.js';
import { anchors } from './pages/repertory.js';

/*
 *	GSAP + Scrolltrigger notes -- leave me here
 *
 * 	great gsap animation example
 * 	https://greensock.com/mistakes/#use-loops
 *
 * 	good animation strategy for barba + gsap
 * 	https://codepen.io/cameronknight/project/editor/DNjrzY
 *
 * 	excelent thread on scrolltrigger kill/start scrolltrigger:
 * 	https://greensock.com/forums/topic/26585-scrolltrigger-not-working-after-barba-transition/
 *
 * 	excelent example on how to kill/start scrolltrigger:
 * 	https://stackblitz.com/edit/web-platform-j6l93d?file=js%2Fmain.js
 *
 */

// do something before the transition starts
barba.hooks.before(() => {
	// update header
	if (document.querySelector('.header')) {
		updateHeader(document);
	}

	// update menu
	if (document.querySelector('.header')) {
		updateMenu(document);
	}
});

// kill old ScrollTriggers
barba.hooks.afterLeave(() => {
	let count = 0;

	let triggers = ScrollTrigger.getAll();
	triggers.forEach(function (trigger) {
		count += 1;
		trigger.kill();
	});
});

// do something before next page enter
barba.hooks.beforeEnter((data) => {
	// init search
	if (data.next.container.querySelector('.search')) {
		searchAnimation(data.next.container);
	}

	// init main menu
	if (data.next.container.querySelector('.main-menu')) {
		mainMenu(data.next.container);
	}
});

// do something after the transition finishes
barba.hooks.after((data) => {
	/**
	 * 	All scripts below need to be init in load eventListener down below /!\
	 *
	 */

	// load search
	if (document.querySelector('.search')) {
		searchScrollAnimation(document);
		search(document);
	}
});

function leaveAnimation(e) {
	return new Promise(async (resolve) => {
		await gsap
			.to(e, {
				yPercent: -100,
				duration: 1.1,
				ease: 'none',
			})
			.then();
		resolve();
	});
}

function enterAnimation(e) {
	return new Promise((resolve) => {
		gsap.from(e, {
			yPercent: 100,
			duration: 1.1,
			ease: 'none',
		}).then(resolve());
	});
}

barba.init({
	// debug: true,
	timeout: 5000,
	preventRunning: true, // prevent page “force reload” when the user clicks on an eligible link during a transition is running
	transitions: [
		{
			sync: true,
			name: 'slide-up',
			leave: ({ current }) => leaveAnimation(current.container),
			enter: ({ next }) => enterAnimation(next.container),
		},
	],
	views: [
		{
			namespace: 'alumni',
			beforeEnter(data) {
				// accordion
				if (data.next.container.querySelector('.accordion.list--alumni')) {
					accordion(data.next.container, '.accordion.list--alumni');
				}
				// alumni slider
				if (data.next.container.querySelector('.swiper--alumni')) {
					slider(data.next.container, '.swiper--alumni');
				}
				// alumni plyr
				if (data.next.container.querySelector('#player')) {
					plyr();
				}
			},
		},
		{
			namespace: 'yearbook-item',
			beforeEnter(data) {
				// accordion
				if (data.next.container.querySelector('.accordion.list--yearbook')) {
					accordion(data.next.container, '.accordion.list--yearbook');
				}
				// PDF slider
				if (data.next.container.querySelector('.swiper--pdf')) {
					viewer(data.next.container, '.swiper--pdf');
				}
			},
		},
		{
			namespace: 'repertory',
			beforeEnter(data) {
				if (data.next.container.querySelector('.list--repertory')) {
					anchors(data.next.container);
				}
			},
		},
		// {
		// 	namespace: 'informations',
		// 	beforeEnter(data) {
		// 		// accordion
		// 		if (document.querySelector('.accordion.info__item')) {
		// 			accordion(data.next.container, '.accordion.info__item');
		// 			scrollToAcc(data.next.container);
		// 		}
		// 	},
		// },
	],
});

// load once
window.addEventListener('load', function () {
	// size the viewport
	if (document.querySelector('html')) {
		viewport();
	}

	// load search
	if (document.querySelector('.search')) {
		searchScrollAnimation(document);
		search(document);
	}

	// load menu once
	if (document.querySelector('.nav--small')) {
		simpleMenu();
	}
});
